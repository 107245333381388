





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({
    components: {
    }
})
export default class ConnectionTabOutput extends Vue{
    @Global.State('lang') lang;

    @Prop() output;
    @Prop() output_connected;
    @Prop() output_schedules;
    @Prop() triggers_list;


    @Watch('output_schedules', { deep: true, immediate: true})
    get output_next_schedule(){
        return Utils.getTodayScheduleTime(this.output_schedules, this.output.gpio_address);
    }

    get triggers_count(){
        return this.triggers_list.filter((trigger) => trigger.enabled).length;
    }
}

